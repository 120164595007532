import React from 'react';
import { useSpring, animated } from 'react-spring';

import"../../CSS/ProjectPageStyle.css";

const WorkExperience = () => {
    const props = useSpring({
        opacity: 1,
        color: 'white',
        from: { opacity: 0 },
        delay: '2000'
    })
    return (
    <animated.div style={props}>
        <div className="project-page">
            <div className="project-title">Work Experience</div>
            <div className="experience-block">
                <div className="cv-box">
                    <div className="cv-box-title">Schlumberger Internship </div>
                    <div className="cv-box-date">
                        April 2020 - July 2020
                    </div>
                </div>
                <div>
                    Data Science project researching into improving extraction results of data insights from
                    oilfield documents using  Natural Language Processing (NLP) and Machine Arguing (MA) methods.
                </div>
            </div>
            <div className="experience-block">
                <div className="cv-box">
                    <div className="cv-box-title">Schlumberger Placement </div>
                    <div className="cv-box-date">
                        April 2019 - September 2019
                    </div>
                </div>
                <div>
                    Developed a full stack NLP web application for topic extraction of oilfield documents using
                    statistical NLP methods.
                </div>
            </div>
        </div>
    </animated.div>
    )
}

export default WorkExperience;
