import React, {Component} from 'react';
import * as Pixi from 'pixi.js';
import {motion} from "framer-motion";
import {Link} from "react-router-dom";
import {Container, Row, Col} from "react-bootstrap";


import "../CSS/GamePageStyle.css";



class GamePageComponent extends Component {

    app;
    gameCanvas;

    constructor(props) {
        super(props);

        const style = new Pixi.TextStyle({
            fontFamily: 'Courier New',
            fontSize: 36,
            fontStyle: 'normal',
            fill: "#ffffff",
            stroke: "black",
            fontWeight: 'normal',
            align: "center",
            strokeThickness: 5,
            wordWrap: true,
            wordWrapWidth: window.innerWidth * (0.75),
        });

        this.state = {
            height: window.innerHeight,
            width: window.innerWidth,
            bullets: [],
            asteriods: [],
            ship: null,
            style: style,
        };

        this.generateAsteriods = this.generateAsteriods.bind(this);
        this.generateStars = this.generateStars.bind(this);
        this.generateShip = this.generateShip.bind(this);
        this.generateBullet = this.generateBullet.bind(this);
        this.addTicker = this.addTicker.bind(this);

    }

    generateAsteriods(){
        var input = "Hi, I'm Athi Dharma! > Fourth Year Computing Student ( MEng AI ) \n" +
        "> Game Development Enthusiast \n" +
        "> Full Stack Web Developer \n" +
        "> Natural Language Processing Practitioner";
        let textArray = input.split(/[ ,]+/);
        const style = new Pixi.TextStyle({
            fontFamily: 'Courier New',
            fontSize: 18,
            fontStyle: 'normal',
            fill: "#A59692",
            stroke: "black",
            fontWeight: 'normal',
            align: "center",
            strokeThickness: 5,
        });

        const app = this.app;

        const asteroidArray = textArray.map(function(letter, index) {
            const text = new Pixi.Text(letter.toString(), style);
            text.x = Math.floor(Math.random() * window.innerWidth);
            text.y = Math.floor(Math.random() * window.innerHeight);

            text.direction = Math.random() * Math.PI * 2;
            text.turningSpeed = Math.random() - 0.8;
            text.speed = 2 + Math.random() * 2;

            app.stage.addChild(text);
            return text;
        });

        this.setState({
            asteriods: asteroidArray,
        });
    }

    generateStars() {
        const starAmount = 200;

        const starBoundsPadding = 10;
        const starBounds = new Pixi.Rectangle(-starBoundsPadding,
            -starBoundsPadding,
            this.app.screen.width + starBoundsPadding * 2,
            this.app.screen.height + starBoundsPadding * 2);

        let starColours = [0x98FB98, 0x87CEFA, 0xFFFACD, 0xFFFF00, 0xFFA500, 0xFF8C00, 0xFF4500];

        const vecShader = `
            attribute vec2 aVertexPosition;
            attribute vec2 aTextureCoord;

            uniform mat3 projectionMatrix;

            varying vec2 vTextureCoord;
            void main()
            {            
                gl_Position = vec4((projectionMatrix * vec3(aVertexPosition, 1.0)).xy, 0.0, 1.0);
                vTextureCoord = aTextureCoord;
            }
         `;
        const fragShader = `
            uniform sampler2D uSampler;
            uniform float brightness; //smaller is brighter
            uniform float time;
            uniform vec2 resolution;
            uniform vec2 glowPosition;
            uniform vec3 glowColor;
            uniform vec2 mouse;
    
      
            void main()
            {
                float maxdist = length(resolution);
                float distance = (maxdist - distance(glowPosition, gl_FragCoord.xy)) / maxdist;
                float intensity = pow(distance, brightness);
                vec2 pixel = gl_FragCoord.xy / resolution.xy;
                vec4 color = texture2D(uSampler, pixel);
                vec3 glow = glowColor * intensity;
                gl_FragColor = vec4(sin(time),mouse.x/resolution.x,mouse.y/resolution.y,1.0);
            }
        `;

        var uniforms = {
            brightness:{type:"f", value:10},
            glowPosition:{type:"vec2", value:{x:20, y:480}},
            resolution:{type:"vec2", value:[window.innerWidth,window.innerHeight]},
            glowColor:{type:"vec3", value:[0,0,1]},
            mouse:{type:'v2', value: {x:0,y:0}},
            time: {
                type:"f",
                value:0
            },

        };



    //

        var simpleShader = new Pixi.Filter(vecShader, fragShader, uniforms);

        var glowFilter = new Pixi.Sprite(Pixi.Texture.EMPTY);
        glowFilter.width = window.innerWidth;
        glowFilter.height = window.innerHeight;
        glowFilter.filters = [simpleShader];
        this.app.stage.addChild(glowFilter);


        let starArray = [];
        for (let i = 0; i < starAmount; i++) {

            const starDot = new Pixi.Graphics();
            const radius = (Math.random() * 2) + 1;
            const colourRnd = Math.floor(Math.random() * 7);
            const colour = starColours[colourRnd];
            starDot.beginFill(colour);
            starDot.drawCircle(0, 0, radius);
            starDot.cacheAsBitmap = true;
            starDot.x = Math.floor(Math.random() * window.innerWidth);
            starDot.y = Math.floor(Math.random() * window.innerHeight);
            if (i % 10 === 0) {
                starDot.velocityX = (Math.random() * 2) - 1;
                starDot.velocityY = (Math.random() * 2) - 1;
            } else {
                starDot.velocityX = 0;
                starDot.velocityY = 0;
            }

            // starDot.filters = [simpleShader];

            this.app.stage.addChild(starDot);
            starArray.push(starDot);
        }

        this.app.ticker.add((delta) => {
            starArray.forEach(function(star) {

                if (star.x < starBounds.x) {
                    star.x += starBounds.width;
                } else if (star.x > starBounds.x + starBounds.width) {
                    star.x -= starBounds.width;
                }

                if (star.y < starBounds.y) {
                    star.y += starBounds.height;
                } else if (star.y > starBounds.y + starBounds.height) {
                    star.y -= starBounds.height;
                }
                star.x += star.velocityX / 2;
                star.y += star.velocityY / 2;


            });
        });

        this.setState({
            stars: starArray,
        });

    }

    generateShip() {

        // Define the Pixi local object
        const ship = new Pixi.Graphics();

        const centreX = window.innerWidth / 2;
        const centreY = window.innerHeight / 2;
        const shipWidth = 20;
        const shipHeight = 30;
        ship.beginFill(0x000000);
        ship.lineStyle(1, 0xFFFFFF, 1);
        ship.moveTo(centreX, centreY);
        ship.lineTo(centreX + shipWidth, centreY + 1.5 * shipHeight);
        ship.lineTo(centreX, centreY + shipHeight);
        ship.lineTo(centreX - shipWidth, centreY + 1.5 * shipHeight);
        ship.lineTo(centreX, centreY);
        ship.closePath();
        ship.endFill();
        ship.pivot.x = centreX;
        ship.pivot.y = (centreY + shipHeight);
        ship.x = centreX;
        ship.y = centreY;

        ship.beginHole();
        ship.drawCircle(centreX, (centreY + shipHeight), 5);
        ship.endHole();

        this.setState({
            ship: ship
        });

        this.app.stage.addChild(ship);
    }

    generateBullet() {

        let bulletArray = this.state.bullets;
        let maxBulletArraySize = 5;
        if (bulletArray.length < maxBulletArraySize) {
            // Define the bullet pixi object
            const rotation = this.state.ship.rotation;
            const startPositionX = this.state.ship.position.x + Math.cos(rotation) * 20;
            const startPositionY = this.state.ship.position.y + Math.sin(rotation) * 20;

            var bullet = new Pixi.Graphics();
            bullet.lineStyle(0);
            bullet.beginFill(0xDE3249, 1);
            bullet.drawCircle(startPositionX, startPositionY, 3);
            bullet.endFill();
            bullet.pivot.x = startPositionX;
            bullet.pivot.y = (startPositionY + 20);
            bullet.x = startPositionX;
            bullet.y = startPositionY;
            bullet.rotation = rotation - 90 * Math.PI / 180;
            this.app.stage.addChild(bullet);

            // Add the bullet to the local react state

            bulletArray.push(bullet);

            this.setState({
                bullets: bulletArray,
            });
        }
    }

    addTicker(){

        // Ticker is a game loop
        this.app.ticker.add((delta) => {

            let ship = this.state.ship;

            const asteriodBoundsPadding = 10;
            const asteriodBounds = new Pixi.Rectangle(-asteriodBoundsPadding,
                -asteriodBoundsPadding,
                this.app.screen.width + asteriodBoundsPadding * 2,
                this.app.screen.height + asteriodBoundsPadding * 2);


            const mousepositionX = this.app.renderer.plugins.interaction.eventData.data.global.x;
            const mousepositionY = this.app.renderer.plugins.interaction.eventData.data.global.y;
            const posX = this.state.ship.position.x;
            const posY = this.state.ship.position.y;

            //console.log(mousepositionX + " , " + mousepositionY + " vs " +  this.app.renderer.plugins.interaction.eventData.data.global.x)

            var dist_Y = mousepositionY - posY;
            var dist_X = mousepositionX - posX;
            var angle = Math.atan2(dist_Y, dist_X) + 90 * Math.PI / 180;

            ship.rotation = angle;

            const asteriods = this.state.asteriods;
            const bullets = this.state.bullets;

            // Game win condition
            if( asteriods.length === 0 ) {

                // Win game UI
                const winGame = new Pixi.Text("You won!", this.state.style);
                winGame.x = window.innerWidth / 2 - winGame.width / 2;
                winGame.y = window.innerHeight / 2 - winGame.height / 2;
                winGame.interactive = true;
                this.app.stage.addChild(winGame);

                // Game Resources tidy up
                ship.visible = false;

                for (let i = 0; i < this.state.bullets.length; i++) {
                    this.state.bullets[i].destroy();
                }
                for (let i = 0; i < this.state.asteriods.length; i++) {
                    this.state.asteriods[i].destroy();
                }
                this.setState({
                    bullets: [],
                    asteriods: []
                });
                this.app.stop();

            } else {


                const removeBullets = [...this.state.bullets];
                const removeAsteroids = [...this.state.asteriods];
                const bulletSpeed = 5;

                // Set asteroid out of screen movement
                for (let i = 0; i < asteriods.length; i++) {
                    const asteriod = asteriods[i];
                    asteriod.direction += asteriod.turningSpeed * 0.01;
                    asteriod.x += Math.sin(asteriod.direction) * asteriod.speed;
                    asteriod.y += Math.cos(asteriod.direction) * asteriod.speed;
                    asteriod.rotation = -asteriod.direction - Math.PI / 2;

                    if (asteriod.x < asteriodBounds.x) {
                        asteriod.x += asteriodBounds.width;
                    } else if (asteriod.x > asteriodBounds.x + asteriodBounds.width) {
                        asteriod.x -= asteriodBounds.width;
                    }

                    if (asteriod.y < asteriodBounds.y) {
                        asteriod.y += asteriodBounds.height;
                    } else if (asteriod.y > asteriodBounds.y + asteriodBounds.height) {
                        asteriod.y -= asteriodBounds.height;
                    }
                }

                // Collision detection for bullets and asteroid
                for (var bullet = bullets.length - 1; bullet >= 0; bullet--) {
                    bullets[bullet].position.x += Math.cos(bullets[bullet].rotation) * bulletSpeed;
                    bullets[bullet].position.y += Math.sin(bullets[bullet].rotation) * bulletSpeed;

                    for (let i = 0; i < asteriods.length; i++) {

                        var bounds = asteriods[i].getBounds();

                        if(bounds.x + bounds.width >= bullets[bullet].position.x && bounds.x <= bullets[bullet].position.x) {
                            if(bounds.y + bounds.height >= bullets[bullet].position.y && bounds.y <= bullets[bullet].position.y) {
                                removeAsteroids.splice(i, 1);
                                asteriods[i].visible = false;
                            }
                        }
                    }

                    if (bullets[bullet].position.x < 0 || bullets[bullet].position.y < 0 || bullets[bullet].position.x > window.innerWidth || bullets[bullet].position.y > window.innerHeight) {
                        removeBullets.splice(bullet, 1);
                        bullets[bullet].destroy();
                    }

                    this.setState({
                        bullets: removeBullets,
                        asteriods: removeAsteroids
                    });
                }
            }
        });
    }


    componentDidMount() {
        console.log(`Inner Width: ${window.innerWidth}, Inner Height: ${window.innerHeight}`);
        // Set up Pixi Application
        this.app = new Pixi.Application({
            autoResize: true,
            width: window.innerWidth,
            height: window.innerHeight,
            resizeTo: window,
            resolution: devicePixelRatio,
            antialias: true
        });
        console.log(devicePixelRatio)

        // Attach pixi application to view and make it active
        this.gameCanvas.appendChild(this.app.view);
        this.app.start();
        this.app.interactive = true;


        this.generateStars();

        // Create buttons
        const playGameText = new Pixi.Text("Play Game", this.state.style);
        playGameText.x = window.innerWidth / 2 - playGameText.width / 2;
        playGameText.y = window.innerHeight / 2 + playGameText.height / 2;
        playGameText.interactive = true;
        this.app.stage.addChild(playGameText);


        // Menu UI Elements
        playGameText.on('pointerdown', () => {

            // Removed ui elements
            playGameText.interactive = false;
            playGameText.visible = false;

            // Load init game objects
            this.generateStars();
            this.generateAsteriods();
            this.generateShip();


            // Load the dynamic game objects
            this.app.renderer.plugins.interaction.on('pointerdown', () => {
                this.generateBullet();
            });

            // Main game loop
            this.addTicker();

        });

    }



    componentWillUnmount() {
        // Remove all the game objects used in the game
        for (let i = 0; i < this.state.bullets.length; i++) {
            this.state.bullets[i].destroy();
        }
        for (let i = 0; i < this.state.asteriods.length; i++) {
            this.state.asteriods[i].destroy();
        }
        this.setState({
            bullets: [],
            asteriods: []
        });

        // Tidy up the app
        this.app.stop();
    }

    render() {

        // const titleText = new Pixi.Text("Hi, I'm Athi Dharma!", this.state.style);
        // titleText.x = window.innerWidth / 2 - titleText.width / 2;
        // titleText.y = window.innerHeight / 2 -  8 * titleText.height / 2;
        // const bodyText = new Pixi.Text("> Fourth Year Computing Student (MEng AI)\n" +
        //     "> Game Development Enthusiast \n" +
        //     "> Full Stack Web Developer \n" +
        //     "> Natural Language Processing Practitioner", this.state.bodyStyle);

        let component = this;

        const pageVariants = {
            initial: {
                opacity: 0,
                y: "-100vh",
                scale: 0.8
            },
            in: {
                opacity: 1,
                y: 0,
                scale: 1
            },
            out: {
                opacity: 0,
                y: "100vh",
                scale: 1.2
            }
        };

        const pageTransition = {
            duration: 0.5,
            transition: "linear"
        };

        return (
            <motion.div
                initial="initial"
                animate="in"
                exit="out"
                variants={pageVariants}
                transition={pageTransition}
            >
                <Container className="game-container" fluid>
                    <Row className="landing-row">
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} className="landing-col">
                            <Link to="/" className="arrow-up" />
                            <div className="div-up"> Home </div>
                        </Col>
                    </Row>
                    <Row className="game-row">
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} className="game-col">
                            <div ref={(thisDiv) => {
                                component.gameCanvas = thisDiv
                            }}/>
                        </Col>
                    </Row>
                </Container>
            </motion.div>

        );
    }
}

export default GamePageComponent;