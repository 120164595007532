import React, {useState} from 'react';
import { useSpring, animated, useTransition } from 'react-spring';

import"../../CSS/ProjectPageStyle.css";

const Hackathon = () => {
    const props = useSpring({
        from: { opacity: 0, color: 'black' },
        to: [
            { opacity: 1, color: '#ffaaee' },            
            { opacity: .5, color: '#008000' },
            { opacity: 1, color: 'white' }
        ]
      });
    return  (
    <animated.div style={props}>
        <div className="project-page">
            <div className="project-title">Hackathons</div>
            <div className="experience-block">
                <div className="cv-box">
                    <div className="cv-box-title">Health Hack Event Planning</div>
                    <div className="cv-box-date">
                        2019 and 2020
                    </div>
                </div>
                <div>
                    Led the organisation of Health Hack 2019 and 2020 working with multiple different societies at Imperial.
                </div>
            </div>
            <div className="experience-block">
                <div className="cv-box">
                    <div className="cv-box-title">IC Hack Event Planning</div>
                    <div className="cv-box-date">
                        2019 and 2020
                    </div>
                </div>
                <div>
                    Helped organise IC Hack 19 and 20 as part of the DoCSoc commitee mainly working in Hacker Experience.
                </div>
            </div>
            <div className="experience-block">
                <div className="cv-box">
                    <div className="cv-box-title">IC Hack </div>
                    <div className="cv-box-date">
                        January 2018
                    </div>
                </div>
                <div>
                    Created an Android App (Java) which utilises Microsoft Cognitive Services to
                    identify food ingredients and returns recipes from an online recipe API.
                </div>
            </div>
            <div className="experience-block">
                <div className="cv-box">
                    <div className="cv-box-title">Angel Hack</div>
                    <div className="cv-box-date">
                        August 2017
                    </div>
                </div>
                <div>
                    Created a chrome extension to manage Youtube playlists using Javascript
                    and Youtube Data API.
                </div>
            </div>
        </div>
    </animated.div>
    )
}

export default Hackathon;
