import React, {Component} from 'react';
import './App.css';
import LandingPageComponent from "./Components/LandingPage";
import ContactPageComponent from "./Components/ContactPage";
import ProjectV2PageComponent from "./Components/ProjectV2Page";
import AboutPageComponent from "./Components/AboutPage";
import GamePageComponent from "./Components/GamePage";
import 'bootstrap/dist/css/bootstrap.min.css';
import {AnimatePresence} from "framer-motion";

import {
    BrowserRouter as Router,
    Switch,
    Route,
    useLocation
} from "react-router-dom";

class App extends Component {



    constructor(props) {
        super(props);

        this.state = {

        };
    }

    componentDidMount() {
        const REACT_VERSION = React.version;
        console.log(`Main Inner Width: ${window.innerWidth}, Main Inner Height: ${window.innerHeight}`);
        console.log(`Using React Version: ${REACT_VERSION}`);
    }

    componentWillUnmount() {

    }

    render() {
        return (
            <div className="app-container">
                <Router>
                    <SwitchHook />
                </Router>
            </div>
        );
    }
}

function SwitchHook() {
    const location = useLocation();
    return (
        <AnimatePresence exitBeforeEnter>
            <Switch location={location} key={location.pathname}>
                <Route path="/about" component={AboutPageComponent} />
                <Route path="/project" component={ProjectV2PageComponent} />
                <Route path="/game" component={GamePageComponent} />
                <Route path="/contact" component={ContactPageComponent} />
                <Route path="/" component={LandingPageComponent} />
            </Switch>
        </AnimatePresence>
    )
}

export default App;
