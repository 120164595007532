import React, { Component } from 'react';
import {Container, Row, Col} from "react-bootstrap";
import {motion} from "framer-motion";
import {Link} from "react-router-dom";
import cvFile from "../Assests/CV2020Athithan Dharmaratnam.pdf";
import"../CSS/ContactPageStyle.css";
import * as Pixi from 'pixi.js';

class ContactPageComponent extends Component {

    app;
    gameCanvas;

    constructor(props) {
        super(props);

        this.state = {
            height: window.innerHeight,
            width: window.innerWidth
        };

        this.generateStars = this.generateStars.bind(this);
    }

    generateStars() {
        const starAmount = 200;

        const starBoundsPadding = 10;
        const starBounds = new Pixi.Rectangle(-starBoundsPadding,
            -starBoundsPadding,
            this.app.screen.width + starBoundsPadding * 2,
            this.app.screen.height + starBoundsPadding * 2);

        let starColours = [0x98FB98, 0x87CEFA, 0xFFFACD, 0xFFFF00, 0xFFA500, 0xFF8C00, 0xFF4500];

        const vecShader = `
            attribute vec2 aVertexPosition;
            attribute vec2 aTextureCoord;

            uniform mat3 projectionMatrix;

            varying vec2 vTextureCoord;
            void main()
            {            
                gl_Position = vec4((projectionMatrix * vec3(aVertexPosition, 1.0)).xy, 0.0, 1.0);
                vTextureCoord = aTextureCoord;
            }
         `;
        const fragShader = `
            uniform sampler2D uSampler;
            uniform float brightness; //smaller is brighter
            uniform float time;
            uniform vec2 resolution;
            uniform vec2 glowPosition;
            uniform vec3 glowColor;
            uniform vec2 mouse;
    
      
            void main()
            {
                float maxdist = length(resolution);
                float distance = (maxdist - distance(glowPosition, gl_FragCoord.xy)) / maxdist;
                float intensity = pow(distance, brightness);
                vec2 pixel = gl_FragCoord.xy / resolution.xy;
                vec4 color = texture2D(uSampler, pixel);
                vec3 glow = glowColor * intensity;
                gl_FragColor = vec4(sin(time),mouse.x/resolution.x,mouse.y/resolution.y,1.0);
            }
        `;

        var uniforms = {
            brightness:{type:"f", value:10},
            glowPosition:{type:"vec2", value:{x:20, y:480}},
            resolution:{type:"vec2", value:[window.innerWidth,window.innerHeight]},
            glowColor:{type:"vec3", value:[0,0,1]},
            mouse:{type:'v2', value: {x:0,y:0}},
            time: {
                type:"f",
                value:0
            },

        };



        //

        var simpleShader = new Pixi.Filter(vecShader, fragShader, uniforms);

        var glowFilter = new Pixi.Sprite(Pixi.Texture.EMPTY);
        glowFilter.width = window.innerWidth;
        glowFilter.height = window.innerHeight;
        glowFilter.filters = [simpleShader];
        this.app.stage.addChild(glowFilter);


        let starArray = [];
        for (let i = 0; i < starAmount; i++) {

            const starDot = new Pixi.Graphics();
            const radius = (Math.random() * 2) + 1;
            const colourRnd = Math.floor(Math.random() * 7);
            const colour = starColours[colourRnd];
            starDot.beginFill(colour);
            starDot.drawCircle(0, 0, radius);
            starDot.cacheAsBitmap = true;
            starDot.x = Math.floor(Math.random() * window.innerWidth);
            starDot.y = Math.floor(Math.random() * window.innerHeight);
            if (i % 10 === 0) {
                starDot.velocityX = (Math.random() * 2) - 1;
                starDot.velocityY = (Math.random() * 2) - 1;
            } else {
                starDot.velocityX = 0;
                starDot.velocityY = 0;
            }

            // starDot.filters = [simpleShader];

            this.app.stage.addChild(starDot);
            starArray.push(starDot);
        }

        this.app.ticker.add((delta) => {
            starArray.forEach(function(star) {

                if (star.x < starBounds.x) {
                    star.x += starBounds.width;
                } else if (star.x > starBounds.x + starBounds.width) {
                    star.x -= starBounds.width;
                }

                if (star.y < starBounds.y) {
                    star.y += starBounds.height;
                } else if (star.y > starBounds.y + starBounds.height) {
                    star.y -= starBounds.height;
                }
                star.x += star.velocityX / 2;
                star.y += star.velocityY / 2;


            });
        });

        this.setState({
            stars: starArray,
        });
    }


    componentDidMount() {
        console.log(`Inner Width: ${window.innerWidth}, Inner Height: ${window.innerHeight}`);
        // Set up Pixi Application
        this.app = new Pixi.Application({
            autoResize: true,
            width: window.innerWidth,
            height: window.innerHeight,
            resizeTo: window,
            resolution: devicePixelRatio,
            antialias: true
        });
        console.log(devicePixelRatio)

        // Attach pixi application to view and make it active
        this.gameCanvas.appendChild(this.app.view);
        this.app.start();
        this.app.interactive = true;

        this.generateStars();
    }


    componentWillUnmount() {
        // Tidy up the app
        this.app.stop();
    }

    render() {

        const pageVariants = {
            initial: {
                opacity: 0,
                x: "100vh",
                scale: 0.8
            },
            in: {
                opacity: 1,
                x: 0,
                scale: 1
            },
            out: {
                opacity: 0,
                x: "-100vh",
                scale: 1.2
            }
        };

        const pageTransition = {
            duration: 0.5,
            transition: "linear"
        };

        let component = this;

        return (
            <motion.div className="contact-page-container"
                        initial="initial"
                        animate="in"
                        exit="out"
                        variants={pageVariants}
                        transition={pageTransition}
            >
                <link href='https://fonts.googleapis.com/css?family=Exo+2' rel='stylesheet' type='text/css' />
                <link href="//netdna.bootstrapcdn.com/font-awesome/4.0.3/css/font-awesome.css" rel="stylesheet" />
                <Container className="contact-container" fluid>
                    <Row className="contact-row">
                        <Col xs={10} sm={11} md={11} lg={11} xl={11} className="contact-col">
                            <Container className="contact-col-container" fluid>
                                <Row className="first-row">
                                    <Col xs={0} sm={1} md={1} lg={1} xl={1} className="empty-contact-col">
                                    </Col>
                                    <Col xs={6} sm={4} md={4} lg={4} xl={4} className="linkedin-contact-col">
                                        <a href="https://www.linkedin.com/in/athithan-dharmaratnam-b35872151/" className="linkedin-button">
                                            <div className="fa fa-linkedin fa-2x"></div>
                                        </a>
                                    </Col>
                                    <Col xs={0} sm={2} md={2} lg={2} xl={2} className="empty-contact-col">
                                    </Col>
                                    <Col xs={6} sm={4} md={4} lg={4} xl={4} className="github-contact-col">
                                        <a href="https://github.com/Rt247" className="github-button">
                                            <div className="fa fa-github fa-2x"></div>
                                        </a>
                                    </Col>
                                    <Col xs={0} sm={1} md={1} lg={1} xl={1}className="empty-contact-col">
                                    </Col>
                                </Row>
                                <Row className="second-row">
                                    <Col xs={6} sm={4} md={4} lg={4} xl={4} className="facebook-contact-col">
                                        <a href="https://www.facebook.com/rtdharma/" className="facebook-button">
                                            <div className="fa fa-facebook fa-2x"></div>
                                        </a>
                                    </Col>
                                    <Col xs={0} sm={1} md={1} lg={1} xl={1}className="empty-contact-col">
                                    </Col>
                                    <Col xs={0} sm={2} md={2} lg={2} xl={2} className="sun-col">
                                        <div className="sun"> </div>
                                    </Col>
                                    <Col xs={0} sm={1} md={1} lg={1} xl={1}className="empty-contact-col">
                                    </Col>
                                    <Col xs={6} sm={4} md={4} lg={4} xl={4} className="youtube-contact-col">
                                        <a href="https://www.youtube.com/channel/UCU3QxJpZUZ8dhyEClcZuOhw?view_as=subscriber" className="youtube-button">
                                            <div className="fa fa-youtube fa-2x"></div>
                                        </a>
                                    </Col>
                                </Row>
                                <Row className="third-row">
                                    <Col xs={0} sm={1} md={1} lg={1} xl={1}className="empty-contact-col">
                                    </Col>
                                    <Col xs={6} sm={4} md={4} lg={4} xl={4} className="email-contact-col">
                                        <a href="malito:artidharma@googlemail.com" className="email-button">
                                            <div className="fa fa-envelope fa-2x"></div>
                                        </a>
                                    </Col>
                                    <Col xs={0} sm={2} md={2} lg={2} xl={2} className="empty-contact-col">
                                    </Col>
                                    <Col xs={6} sm={4} md={4} lg={4} xl={4} className="cv-contact-col">
                                        <a href={cvFile} className="cv-button">
                                            <img className="cv-image" src="https://img.icons8.com/ios/50/000000/open-resume.png" alt=""/>
                                        </a>
                                    </Col>
                                    <Col xs={0} sm={1} md={1} lg={1} xl={1}className="empty-contact-col">
                                    </Col>
                                </Row>
                                </Container>
                        </Col>
                        <Col xs={2} sm={1} md={1} lg={1} xl={1} className="right-col">
                            <div className="div-right"> Home </div>
                            <Link to="/" className="arrow-right" />
                        </Col>
                    </Row>
                </Container>
                <div className="star-background" ref={(thisDiv) => {
                    component.gameCanvas = thisDiv
                }}/>

            </motion.div>
        );
    }
}


export default ContactPageComponent;

