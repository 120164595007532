import React from 'react';
import { useSpring, animated } from 'react-spring';

import"../../CSS/ProjectPageStyle.css";

const UniProject = () => {
    const props = useSpring({
        opacity: 1,
        color: 'white',
        from: { opacity: 0 },
        delay: '2000'
    })
    return (
    <animated.div style={props}>
        <div className="project-page">
            <div className="project-title">Uni Projects</div>
            <div className="experience-block">
                <div className="cv-box">
                    <div className="cv-box-title">Kaizen AR </div>
                    <div className="cv-box-date">
                        September 2018 - December 2018
                    </div>
                </div>
                <div>
                    Led a group of six for the third year group project at Imperial to created an Augmented Reality
                    Kaizen board for a consulting company called Theodo to use in their daily stand up meetings.
                </div>
            </div>
            <div className="experience-block">
                <div className="cv-box">
                    <div className="cv-box-title">Lembas </div>
                    <div className="cv-box-date">
                        May 2018 - June 2018
                    </div>
                </div>
                <div>
                    Lembas was a meal planning application developed in a team of four using agile development iterations
                    and responsive human-centred design methods.
                </div>
            </div>
            <div className="experience-block">
                <div className="cv-box">
                    <div className="cv-box-title">Pintos</div>
                    <div className="cv-box-date">
                        January 2018 - March 2018
                    </div>
                </div>
                <div>
                    Pintos is a simple operating system framework for the 80x86 architecture.
                    In this project I led a team of four to introduce additional features to the Pintos skeleton code.
                </div>
            </div>
            <div className="experience-block">
                <div className="cv-box">
                    <div className="cv-box-title">WACC</div>
                    <div className="cv-box-date">
                        September 2017 - December 2017
                    </div>
                </div>
                <div>
                    In this project, Java and ANTLR 4.7 was used to produce a compiler for a while-like language called WACC to ARM11
                    assembly instructions by performing lexical analysis, syntactic analysis, semantic analysis and code generation
                </div>
            </div>
        </div>
    </animated.div>
    )
}

export default UniProject;
