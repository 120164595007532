import React, { Component } from 'react';
import {Container, Row, Col} from "react-bootstrap";
import * as Pixi from 'pixi.js';
import {GlowFilter} from 'pixi-filters';
import {motion} from "framer-motion";
import {Link} from "react-router-dom";
import { Viewport } from 'pixi-viewport';

import "../CSS/AboutPageStyle.css";

class AboutPageComponent extends Component {

    app;
    gameCanvas;


    constructor(props) {
        super(props);


        var height = window.innerHeight;
        var width = window.innerWidth;

        let isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);

        if (isChrome) {
            console.log("Chrome")
            height = window.visualViewport.height;
            width = window.visualViewport.width;
        }

        height = height * (8 / 10);
        width = width * (10 / 12);
        
        const worldHeight = 2000;
        const worldWidth = 3200;
        const bottomCentreX = worldWidth / 2;
        const bottomCentreY = worldHeight - 250;


        const style = new Pixi.TextStyle({
            fontFamily: 'Courier New',
            fontSize: 26,
            fontStyle: 'normal',
            fill: "#ffffff",
            stroke: "black",
            fontWeight: 'normal',
            align: "center",
            strokeThickness: 5,
            wordWrap: true,
            wordWrapWidth: window.innerWidth * (0.75),
        });

        
        const firstLevel = {
            Music: {
                position: {
                    originX: bottomCentreX,
                    originY: bottomCentreY,
                    scaleFactor: 0.5,
                },
                description: "Adept: Amateur orchestra",
                secondLevel: {
                    Violin: {
                        position: {
                            originX: bottomCentreX,
                            originY: bottomCentreY,
                            scaleFactor: 0.5,
                        },
                        description: "Adept: Grade 8",
                    },
                    Saxophone: {
                        position: {
                            originX: bottomCentreX,
                            originY: bottomCentreY,
                            scaleFactor: 0.5,
                        },
                        description: "Adept: Grade 8",
                    },
                    Piano: {
                        position: {
                            originX: bottomCentreX,
                            originY: bottomCentreY,
                            scaleFactor: 0.5,
                        },
                        description: "Novice: Grade 2",
                    },
                    Soundtracks: {
                        position: {
                            originX: bottomCentreX,
                            originY: bottomCentreY,
                            scaleFactor: 0.5,                            
                        },
                        description: "Favourite Genre",
                    }
                },
            },
            Reading: {
                position: {
                    originX: bottomCentreX,
                    originY: bottomCentreY,
                    scaleFactor: 0.5,
                },
                description: "Adept: Medieval Fiction Fan",
                secondLevel: {
                    GameOfThrones: {
                        position: {
                            originX: bottomCentreX,
                            originY: bottomCentreY,
                            scaleFactor: 0.5,
                        },
                        description: "Favourite Series",
                    },
                    Redwall: {
                        position: {
                            originX: bottomCentreX,
                            originY: bottomCentreY,
                            scaleFactor: 0.5,
                        },
                        description: "Favourite Childhood Series",
                    },
                },
            },
            Sports: {
                position: {
                    originX: bottomCentreX,
                    originY: bottomCentreY,
                    scaleFactor: 0.5,
                },
                description: "Apprentice: Casual Sports Player",
                secondLevel: {
                    Wolves: {
                        position: {
                            originX: bottomCentreX,
                            originY: bottomCentreY,
                            scaleFactor: 0.5,
                        },
                        description: "Favourite Football Team",
                    },
                    Football: {
                        position: {
                            originX: bottomCentreX,
                            originY: bottomCentreY,
                            scaleFactor: 0.5,
                        },
                        description: "Apprentice: Can kick a ball in a straight line",
                    },
                    Tennis: {
                        position: {
                            originX: bottomCentreX,
                            originY: bottomCentreY,
                            scaleFactor: 0.5,
                        },
                        description: "Apprentice: Can hold a 'friendly' rally",
                    },
                },

            },
            Gaming: {
                position: {
                    originX: bottomCentreX,
                    originY: bottomCentreY,
                    scaleFactor: 0.5,
                },
                description: "Adept: Avid Gamer",
                secondLevel: {
                    Bannerlord: {
                        position: {
                            originX: bottomCentreX,
                            originY: bottomCentreY,
                            scaleFactor: 0.5,
                        },
                        description: "The Best Medieval RPG!",
                    },
                    Warzone: {
                        position: {
                            originX: bottomCentreX,
                            originY: bottomCentreY,
                            scaleFactor: 0.5,
                        },
                        description: "Have won a round!",
                    },
                    Fifa: {
                        position: {
                            originX: bottomCentreX,
                            originY: bottomCentreY,
                            scaleFactor: 0.5,
                        },
                        description: "Division 8",
                    },
                },

            }
        };

        this.state = {
            height: height,
            width: width,
            worldHeight: worldHeight,
            worldWidth: worldWidth,
            style: style,
            firstLevel: firstLevel,
            currentText: "Recreation",
            currentX: bottomCentreX,
            currentY: bottomCentreY
        };

        this.generateStars = this.generateStars.bind(this);
        this.genBranch = this.genBranch.bind(this);
        this.genValidCoord = this.genValidCoord.bind(this);
        this.genCoord = this.genCoord.bind(this);
        this.genTree = this.genTree.bind(this);
    }

    generateStars(viewport) {
        const starAmount = 200;

        const starBoundsPadding = 10;
        const starBounds = new Pixi.Rectangle(-starBoundsPadding,
            -starBoundsPadding,
            this.state.worldWidth + starBoundsPadding * 2,
            this.state.worldHeight + starBoundsPadding * 2);

        let starColours = [0x98FB98, 0x87CEFA, 0xFFFACD, 0xFFFF00, 0xFFA500, 0xFF8C00, 0xFF4500];


        let starArray = [];
        for (let i = 0; i < starAmount; i++) {

            const starDot = new Pixi.Graphics();
            const radius = (Math.random() * 2) + 1;
            const colourRnd = Math.floor(Math.random() * 7);
            const colour = starColours[colourRnd];
            starDot.beginFill(colour);
            starDot.drawCircle(0, 0, radius);
            starDot.cacheAsBitmap = true;
            starDot.x = Math.floor(Math.random() * this.state.worldWidth);
            starDot.y = Math.floor(Math.random() * this.state.worldHeight);
            if (i % 10 === 0) {
                starDot.velocityX = (Math.random() * 2) - 1;
                starDot.velocityY = (Math.random() * 2) - 1;
            } else {
                starDot.velocityX = 0;
                starDot.velocityY = 0;
            }

            // starDot.filters = [simpleShader];

            viewport.addChild(starDot);
            starArray.push(starDot);
        }

        this.app.ticker.add((delta) => {
            starArray.forEach(function(star) {

                if (star.x < starBounds.x) {
                    star.x += starBounds.width;
                } else if (star.x > starBounds.x + starBounds.width) {
                    star.x -= starBounds.width;
                }

                if (star.y < starBounds.y) {
                    star.y += starBounds.height;
                } else if (star.y > starBounds.y + starBounds.height) {
                    star.y -= starBounds.height;
                }
                star.x += star.velocityX / 2;
                star.y += star.velocityY / 2;


            });
        });

        this.setState({
            stars: starArray,
        });

    }


    componentDidMount() {

        
        console.log(`Inner Width: ${window.innerWidth}, Inner Height: ${window.innerHeight}`);
        console.log(`Width: ${this.state.width}, Height: ${this.state.height}`);
        // Set up Pixi Application
        this.app = new Pixi.Application({
            autoResize: true,
            width: this.state.width,
            height: this.state.height,
            resolution: devicePixelRatio,
            antialias: true
        });

        // create viewport
        const viewport = new Viewport({
            screenWidth: this.state.width,
            screenHeight: this.state.height,
            worldWidth: this.state.worldWidth,
            worldHeight: this.state.worldHeight,
            interaction: this.app.renderer.plugins.interaction // the interaction module is important for wheel to work properly when renderer.view is placed or scaled
        });

        viewport.clampZoom({
            maxWidth: this.state.worldWidth,
            maxHeight: this.state.worldHeight,
            minHeight: this.state.height / 2,
            minWidth: this.state.width / 2
        })

        viewport.clamp({
            direction: 'all'
        })


        // Attach pixi application to view and make it active
        this.gameCanvas.appendChild(this.app.view);
        this.app.start();
        this.app.interactive = true;

        this.app.stage.addChild(viewport);

        viewport
            .drag()
            .pinch()
            .wheel()
            .decelerate();

        this.generateStars(viewport);

        const bottomCentreX = this.state.worldWidth / 2;
        const bottomCentreY = this.state.worldHeight - 250;

        viewport.snapZoom({
            width: this.state.worldWidth,
            time: 1000,
            ease: "easeInOutSine",
            center: new Pixi.Point(bottomCentreX,bottomCentreY),
            removeOnComplete: true,
        });



        const text = new Pixi.Text("Recreation", this.state.style);
        text.x = bottomCentreX - text.width / 2;
        text.y = bottomCentreY - 10 + text.height / 2;
        text.interactive = true;

        
        viewport.addChild(text);

        // const graphics = new Pixi.Graphics();

        // graphics.lineStyle(4, 0xFFFFFF);
        // graphics.beginFill(0x55335A, 1);
        // graphics.drawStar(100, 100, 4, 1);
        // graphics.endFill();

        // viewport.addChild(graphics);

        text.on('pointerdown', () => {
            
            this.setState({
                currentText: "Recreation",
                currentX: bottomCentreX,
                currentY: bottomCentreY
            });
       

            if (viewport.scaled > 1) {        
                viewport.snapZoom({
                    width: this.state.worldWidth,
                    time: 1000,
                    ease: "easeInOutSine",
                    center: new Pixi.Point(bottomCentreX,bottomCentreY),
                    removeOnComplete: true,
                });           
            } else {                              
                viewport.snap(bottomCentreX, bottomCentreY, {
                    time: 1000,
                    ease: "easeInOutSine",
                    removeOnComplete: true,
                });
            }
        });

        this.genTree(this.state.firstLevel, viewport)

     
    }

    genTree(firstLevel, viewport) {

        const entries = Object.entries(firstLevel);
        const modWorldHeight = (this.state.worldHeight - 300);
        const modWorldWidth = (this.state.worldWidth - 200);
        const validCoordObj = this.genValidCoord(entries, modWorldWidth, modWorldHeight, 100, 100);

        const recreationDescription = "Recreation"

        let indexX = 0;

        const pattern = [2, 1, 2, 2];       
        const boxHeightFactor = [3, 1, 3, 2]

        for (const [activity, firstLevelObjects] of entries) {

            const position = firstLevelObjects.position;
            const secondLevel = firstLevelObjects.secondLevel;
            const description = firstLevelObjects.description;

            const patternSecondLevel = [[1, 0, 2, 3],  [1, 0], [2, 0, 1], [1, 2, 0]];


            let targetPos = this.genCoord(validCoordObj.validCoord, indexX, activity, pattern);

            let originCoord =  this.genBranch(recreationDescription, description, activity, position, viewport, targetPos, this.app)

            if (secondLevel) {

                const secondLevelEntries = Object.entries(secondLevel);

                const modBoxHeight = (validCoordObj.height - validCoordObj.height * 0.2);
                const leftXCoord = targetPos.targetX - validCoordObj.width / 2;
                const leftYCoord = targetPos.targetY - validCoordObj.height;
                const validCoordSecondObj = this.genValidCoord(secondLevelEntries, validCoordObj.width,  modBoxHeight * boxHeightFactor[indexX], leftXCoord, 0);
  
            
                let indexY = 0;
                for (const [secondLevelActivity, secondLevelObjects] of secondLevelEntries) {

                    const secondLevelPosition = secondLevelObjects.position;
                    const secondLevelDescription = secondLevelObjects.description;

                    if (secondLevelPosition) {
                        secondLevelPosition.originX = originCoord.targetX;
                        secondLevelPosition.originY = originCoord.targetY;

                        let targetPos = this.genCoord(validCoordSecondObj.validCoord, indexY, secondLevelActivity, patternSecondLevel[indexX]);

                        this.genBranch(description, secondLevelDescription, secondLevelActivity, secondLevelPosition, viewport, targetPos, this.app);

                    }
                    indexY = indexY + 1;

                }
            }

            indexX = indexX + 1;
        }
    }


    genCoord(validCoord, indexX, actvitiy, pattern) {
        
        const y = pattern[indexX];
        
        const returnCoord = {
            targetX: validCoord[indexX][y][0],
            targetY: validCoord[indexX][y][1]
        };
        return returnCoord;
    }

    genBranch(parentDesc, desc, activity, position, viewport, targetPos, app) {

        let targetX = targetPos.targetX;
        let targetY = targetPos.targetY;

        const text = new Pixi.Text(activity, this.state.style);
        const displacement = 10;
        text.x = targetX - text.width / 2;
        text.y = targetY - text.height - displacement;
        text.interactive = true;
        viewport.addChild(text);

        text.on('pointerdown', () => {

            this.setState({
                currentText: desc,
                currentX: targetX,
                currentY: targetY
            });

            if (2 !== viewport.scaled) {
          
                viewport.snapZoom({
                    width: this.state.width / 2,
                    time: 1000,
                    ease: "easeInOutSine",
                    center: new Pixi.Point(targetX,targetY),
                    removeOnComplete: true,
                });  
                 
            } else {                    
                viewport.snap(targetX, targetY, {
                    time: 1000,
                    ease: "easeInOutSine",
                    removeOnComplete: true,
                });
            }

        });
        
        let lineWidth = 4;
        let hitLength = 4;
        
        let midX = (targetX + position.originX) / 2;
        let midY = (targetY + position.originY) / 2;

        let sprite = Pixi.Sprite.from('gasTexture.png');        
        
        const graphicsUpper = new Pixi.Graphics();

        var polyUpper = new Pixi.Polygon(
            new Pixi.Point(targetX - hitLength, targetY),
            new Pixi.Point(targetX + hitLength, targetY),
            new Pixi.Point(midX, midY),
        );

        graphicsUpper.lineStyle(0);
        graphicsUpper.lineTextureStyle(lineWidth, sprite.texture);
        graphicsUpper.drawPolygon(polyUpper);
        graphicsUpper.endFill();

        graphicsUpper.hitArea = polyUpper;
        graphicsUpper.alpha = 1;
        graphicsUpper.interactive = true;

        graphicsUpper.on('pointerdown', () => { 

            this.setState({
                currentText: parentDesc,
                currentX: position.originX,
                currentY: position.originY
            });

            if (2 !== viewport.scaled) {          
                viewport.snapZoom({
                    width: this.state.width / 2,
                    time: 1000,
                    ease: "easeInOutSine",
                    center: new Pixi.Point(position.originX, position.originY),
                    removeOnComplete: true,
                });  
                 
            } else {                    
                viewport.snap(position.originX, position.originY, {
                    time: 1000,
                    ease: "easeInOutSine",
                    removeOnComplete: true,
                });
            }
        });

        graphicsUpper.pointerover = function() {
            this.alpha = 0.5;
        }
        
        graphicsUpper.pointerout = function() {
            this.alpha = 1;
        }

        viewport.addChild(graphicsUpper);

        const graphicsLower = new Pixi.Graphics();

        var polyLower = new Pixi.Polygon(
            new Pixi.Point(midX, midY),
            new Pixi.Point(position.originX + hitLength, position.originY),
            new Pixi.Point(position.originX - hitLength, position.originY),
        );

        graphicsLower.lineStyle(0);
        graphicsLower.lineTextureStyle(lineWidth, sprite.texture);
        graphicsLower.drawPolygon(polyLower);
        graphicsLower.endFill();
        
        graphicsLower.hitArea = polyLower;
        graphicsLower.alpha = 1;
        graphicsLower.interactive = true;

        graphicsLower.on('pointerdown', () => { 
            
            this.setState({
                currentText: desc,
                currentX: targetX,
                currentY: targetY
            });

            if (2 !== viewport.scaled) {          
                viewport.snapZoom({
                    width: this.state.width / 2,
                    time: 1000,
                    ease: "easeInOutSine",
                    center: new Pixi.Point(targetX,targetY),
                    removeOnComplete: true,
                });  
                 
            } else {                    
                viewport.snap(targetX, targetY, {
                    time: 1000,
                    ease: "easeInOutSine",
                    removeOnComplete: true,
                });
            }
        });

        graphicsLower.pointerover = function() {
            this.alpha = 0.5;
        }
        
        graphicsLower.pointerout = function() {
            this.alpha = 1;
        }

        viewport.addChild(graphicsLower)

        return {
            targetX: targetX,
            targetY: targetY - text.height - displacement
        }

        
    }

    genValidCoord(entries, boxWidth, boxHeight, displaceX, displaceY) {

        let gridSize = entries.length;

        let width = boxWidth / gridSize;
        let height =  boxHeight / gridSize;        

        let validCoord = [];
        for (let i = 0; i < gridSize; i++) {
            let validInnerCoord = [];
            for (let j = 0; j < gridSize; j++) {

                let topLeftX =  i * width + displaceX;
                let topLeftY =  j * height + displaceY;

                let baseOriginX = topLeftX + width / 2;
                let baseOriginY = topLeftY + height;

                validInnerCoord.push([baseOriginX, baseOriginY]);
            }
            validCoord.push(validInnerCoord);
        }

        return {
            validCoord: validCoord,
            width: width,
            height: height
        }
    }


    componentWillUnmount() {
        // Tidy up the app
        this.app.stop();
    }

    render() {

        let component = this;

        const pageVariants = {
            initial: {
                opacity: 0,
                y: "100vh",
                scale: 0.9
            },
            in: {
                opacity: 1,
                y: 0,
                scale: 1
            },
            out: {
                opacity: 0,
                y: "-100vh",
                scale: 1.1
            }
        };

        const pageTransition = {
            duration: 0.5,
            transition: "linear"
        };

        

        return (
            <motion.div className="about-page-container"
                        initial="initial"
                        animate="in"
                        exit="out"
                        variants={pageVariants}
                        transition={pageTransition}
            >
                
                <Container className="about-container" fluid> 
                    <Row className="desc-row">
                        <Col xs={4} sm={4} md={4} lg={4} xl={4} className="desc-col">
                         
                        </Col>
                        <Col xs={4} sm={4} md={4} lg={4} xl={4} className="desc-col">
                            {this.state.currentText}
                        </Col>
                        <Col xs={4} sm={4} md={4} lg={4} xl={4} className="desc-col">
                            
                        </Col>
                    </Row>       
                    <Row className="about-row">
                        <Col xs={1} sm={1} md={1} lg={1} xl={1} className="left-about-col">   
                           
                        </Col>
                        <Col xs={10} sm={10} md={10} lg={10} xl={10} className="about-col">
                            <div className="about-window" ref={(thisDiv) => {
                                component.gameCanvas = thisDiv
                            }}/>
                        </Col>
                        <Col  xs={1} sm={1} md={1} lg={1} xl={1} className="right-about-col">
                          
                        </Col>
                    </Row>
                    <Row className="landing-row">
                        <Col xs={4} sm={4} md={4} lg={4} xl={4} className="desc-col">
                            Athi Dharma
                        </Col>
                        <Col xs={4} sm={4} md={4} lg={4} xl={4} className="landing-col">
                            <Link to="/" className="arrow-down" />
                            <div className="div-down"> Home </div>
                        </Col>
                        <Col xs={4} sm={4} md={4} lg={4} xl={4} className="desc-col">
                            Level: 22
                        </Col>
                    </Row>
                </Container>
            </motion.div>
        );
    }
}

export default AboutPageComponent;