import React from 'react';
import { useSpring, animated } from 'react-spring';

import"../../CSS/ProjectPageStyle.css";

const PersonalProject = () => {
    const props = useSpring({
        opacity: 1,
        color: 'white',
        from: { opacity: 0 },
        delay: '2000'
    })
    return (
    <animated.div style={props}>
        <div className="project-page">
            <div className="project-title">Personal Projects</div>
            <div className="experience-block">
                <div className="cv-box">
                    <div className="cv-box-title">Personal Website </div>
                    <div className="cv-box-date">
                        July 2020 - Current
                    </div>
                </div>
                <div>
                    Created a personal website with a space and gaming theme using React JS.
                    Explored different web UI themes and learnt about webgl through Pixi JS.
                    Deplyed using Netlify.

                </div>
            </div>
        </div>
    </animated.div>
    )
}

export default PersonalProject;
