import React, {Component} from 'react';
import {motion} from "framer-motion";
import {Link} from "react-router-dom";
import {Container, Row, Col} from "react-bootstrap";

import "../CSS/LandingPageStyle.css";
import * as Pixi from 'pixi.js';



class LandingPageComponent extends Component {

    app;
    gameCanvas;

    constructor(props) {
        super(props);

        this.state = {
            height: window.innerHeight,
            width: window.innerWidth
        };

        this.generateStars = this.generateStars.bind(this);
    }

    generateStars() {
        const starAmount = 200;

        const starBoundsPadding = 10;
        const starBounds = new Pixi.Rectangle(-starBoundsPadding,
            -starBoundsPadding,
            this.app.screen.width + starBoundsPadding * 2,
            this.app.screen.height + starBoundsPadding * 2);

        let starColours = [0x98FB98, 0x87CEFA, 0xFFFACD, 0xFFFF00, 0xFFA500, 0xFF8C00, 0xFF4500];

        const vecShader = `
            attribute vec2 aVertexPosition;
            attribute vec2 aTextureCoord;

            uniform mat3 projectionMatrix;

            varying vec2 vTextureCoord;
            void main()
            {            
                gl_Position = vec4((projectionMatrix * vec3(aVertexPosition, 1.0)).xy, 0.0, 1.0);
                vTextureCoord = aTextureCoord;
            }
         `;
        const fragShader = `
            uniform sampler2D uSampler;
            uniform float brightness; //smaller is brighter
            uniform float time;
            uniform vec2 resolution;
            uniform vec2 glowPosition;
            uniform vec3 glowColor;
            uniform vec2 mouse;
    
      
            void main()
            {
                float maxdist = length(resolution);
                float distance = (maxdist - distance(glowPosition, gl_FragCoord.xy)) / maxdist;
                float intensity = pow(distance, brightness);
                vec2 pixel = gl_FragCoord.xy / resolution.xy;
                vec4 color = texture2D(uSampler, pixel);
                vec3 glow = glowColor * intensity;
                gl_FragColor = vec4(sin(time),mouse.x/resolution.x,mouse.y/resolution.y,1.0);
            }
        `;

        var uniforms = {
            brightness:{type:"f", value:10},
            glowPosition:{type:"vec2", value:{x:20, y:480}},
            resolution:{type:"vec2", value:[window.innerWidth,window.innerHeight]},
            glowColor:{type:"vec3", value:[0,0,1]},
            mouse:{type:'v2', value: {x:0,y:0}},
            time: {
                type:"f",
                value:0
            },

        };


        var simpleShader = new Pixi.Filter(vecShader, fragShader, uniforms);

        var glowFilter = new Pixi.Sprite(Pixi.Texture.EMPTY);
        glowFilter.width = window.innerWidth;
        glowFilter.height = window.innerHeight;
        glowFilter.filters = [simpleShader];
        this.app.stage.addChild(glowFilter);


        let starArray = [];
        for (let i = 0; i < starAmount; i++) {

            const starDot = new Pixi.Graphics();
            const radius = (Math.random() * 2) + 1;
            const colourRnd = Math.floor(Math.random() * 7);
            const colour = starColours[colourRnd];
            starDot.beginFill(colour);
            starDot.drawCircle(0, 0, radius);
            starDot.cacheAsBitmap = true;
            starDot.x = Math.floor(Math.random() * window.innerWidth);
            starDot.y = Math.floor(Math.random() * window.innerHeight);
            if (i % 10 === 0) {
                starDot.velocityX = (Math.random() * 2) - 1;
                starDot.velocityY = (Math.random() * 2) - 1;
            } else {
                starDot.velocityX = 0;
                starDot.velocityY = 0;
            }

            // starDot.filters = [simpleShader];

            this.app.stage.addChild(starDot);
            starArray.push(starDot);
        }

        this.app.ticker.add((delta) => {
            starArray.forEach(function(star) {

                if (star.x < starBounds.x) {
                    star.x += starBounds.width;
                } else if (star.x > starBounds.x + starBounds.width) {
                    star.x -= starBounds.width;
                }

                if (star.y < starBounds.y) {
                    star.y += starBounds.height;
                } else if (star.y > starBounds.y + starBounds.height) {
                    star.y -= starBounds.height;
                }
                star.x += star.velocityX / 2;
                star.y += star.velocityY / 2;


            });
        });

        this.setState({
            stars: starArray,
        });
    }


    componentDidMount() {
        console.log(`Inner Width: ${window.innerWidth}, Inner Height: ${window.innerHeight}`);
        // Set up Pixi Application
        this.app = new Pixi.Application({
            autoResize: true,
            width: window.innerWidth,
            height: window.innerHeight,
            resizeTo: window,
            resolution: devicePixelRatio,
            antialias: true
        });
        console.log(devicePixelRatio)

        // Attach pixi application to view and make it active
        this.gameCanvas.appendChild(this.app.view);
        this.app.start();
        this.app.interactive = true;

        this.generateStars();

     

    }

    componentWillUnmount() {
        this.app.stop();
    }


    render() {

        const pageVariants = {
            initial: {
                opacity: 0,
                scale: 0.8
            },
            in: {
                opacity: 1,
                y: 0,
                scale: 1
            },
            out: {
                opacity: 0,
                scale: 0.8
            }
        };

        const pageTransition = {
            duration: 0.4,
            transition: "linear"
        };

        let component = this;

        return (
            <motion.div className="landing-page-container"
                        initial="initial"
                        animate="in"
                        exit="out"
                        variants={pageVariants}
                        transition={pageTransition}
            >
                <Container className="main-container" fluid>
                    <Row className="top-row">
                        <Col className="top-col">
                            <Link to="/about" className="arrow-up">

                            </Link>
                            <div className="div-up"> About </div>
                        </Col>
                    </Row>

                    <Row className="middle-row">
                        <Col xs={2} sm={1} md={1} lg={1} xl={1} className="left-col">
                            <Link to="/contact" className="arrow-left">

                            </Link>
                            <div className="div-left"> Contact </div>
                        </Col>
                        <Col xs={8} sm={10} md={10} lg={10} xl={10} className="center-col">
                            <div className="text-col">
                                 Hi, I'm Athi Dharma!
                            </div>
                            <div className="text-col">
                                > Imperial Computing Graduate <br/>
                                > Game Development Enthusiast <br/>
                                > Full Stack Web Developer <br/>
                                > Natural Language Processing Practitioner <br/>
                            </div>
                            <div className="text-col">                            
                                Feel free to contact me using the links to the left!
                            </div>
                        </Col>
                        <Col xs={2} sm={1} md={1} lg={1} xl={1} className="right-col">
                            <div className="div-right"> Projects </div>
                            <Link to="/project" className="arrow-right">

                            </Link>
                        </Col>
                    </Row>
                    <Row className="bottom-row">
                        <Col className="bottom-col">
                            <div className="div-down"> Play </div>
                            <Link to="/game" className="arrow-down">

                            </Link>
                        </Col>
                    </Row>
                </Container>
                <div className="star-background" ref={(thisDiv) => {
                    component.gameCanvas = thisDiv
                }}/>

            </motion.div>
        );
    }
}

export default LandingPageComponent;