import React from 'react';
import { useSpring, animated } from 'react-spring';

import"../../CSS/ProjectPageStyle.css";

const GameDev = () => {
    const props = useSpring({
        opacity: 1,
        color: 'white',
        from: { opacity: 0 },
        delay: '2000'
    })
    return (
    <animated.div style={props}>
        <div className="project-page">
            <div className="project-title">Game Development</div>
            <div className="experience-block">
                <div className="cv-box">
                    <div className="cv-box-title">QTClimb </div>
                    <div className="cv-box-date">
                        May 2017 - June 2017
                    </div>
                </div>
                <div>
                    Created a puzzle game in a team of four based on the player finding a Hamiltonian path by traversing
                    the tiles of a randomly generated map created using a recursive algorithm.
                </div>
            </div>
        </div>
    </animated.div>
    )
}

export default GameDev;
